<template>
  <div class="box">
    <img src="/temp/no_data.png" alt="logo">
    <span> 空闲中，暂无数据 </span>
  </div>
</template>

<script>
export default {
  name: 'ShowHallIdleInfo',
  data() {
    return {
      data: {}
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;

  img {
    width: 100px;
  }

  span {
    margin-top: 10px;
    font-size: 20px;
    color: #97d689ff;
  }
}
</style>

